import * as React from 'react';
type ColumnSort = 'ASC' | 'DESC';
export interface IColumn {
  id: string | number;
  label: string;
  style?: any;
  sorting?: ColumnSort;
  sortable?: boolean;
  active?: boolean;
}
export interface IUseTableSort {
  columns: Array<IColumn>;
}
export const useTableSort = (args: IUseTableSort) => {
  const [columns, setColumns] = React.useState(args.columns);
  const handleColumnClick = (id: string | number) => {
    setColumns((prevColumns) =>
      prevColumns.map((item) => {
        if (item.id === id && item.sortable) {
          if (item.active && item.sorting === 'ASC') return { ...item, sorting: 'DESC' };
          else if (item.active && item.sorting === 'DESC') return { ...item, active: false, sorting: 'ASC' };
          else return { ...item, active: true, sorting: 'ASC' };
        }
        return { ...item, sorting: 'ASC', active: false };
      })
    );
  };

  const getSorted = () => {
    return columns
      .filter((item) => item.active)
      .map((item: any) => ({
        key: item.id,
        sort: item.sorting.toLowerCase(),
      }));
  };

  return { columns, setColumns, getSorted, handleColumnClick };
};
