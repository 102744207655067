import { gql } from '@apollo/client';

export const PROJECT_PRICELIST_QUERY = gql`
  query GetProjectPricelist($input: EstateInput!, $project: ProjectInput!) {
    estate(input: $input) {
      assignmentNumber
      streetAddress
      project(input: $project) {
        units {
          status
          assignmentNumber
          apartmentNumber
          floor
          type
          published
          sold
          prices {
            askingPrice
            totalPrice
          }
          debt {
            collectiveDebt
          }
          expences {
            collectiveExpences
            collectiveExpencesPeriod
          }
          rooms {
            bedrooms
            total
          }
          size {
            usableArea
            primaryArea
            grossInternalArea
            openArea
          }
          referenceUrls {
            biddingUrl
          }
          images {
            floorplans {
              url
            }
          }
          parking {
            name
            included
          }
          plot {
            size
          }
        }
      }
    }
  }
`;
